<template>
  <div>
    <div class="header-top">
      <img class="tp-img" src="../../assets/images/project/case-ban.png" />
      <div class="backstyle" @click="goHome()">返回主页</div> 
      <div v-if="id == '1'">
        <div class="tp-title">苏州腾康科技生态岛现代农业生态循环示范产业园</div>
        <div class="tp-ic"></div>
        <div class="tp-body"><div class="underline"></div></div>

        <div class="tp-content">
          <div class="tp-text">项目名称：苏州腾康科技生态岛现代农业生态循环示范产业园<br>科研基地、现代有机农业、生态养殖、观光农业、农村电商，全国最具特色的现代农业生态循环示范产业园</div>
          <div class="middle">
            <img
              class="middle-img"
              src="../../assets/images/project/xishan-2.webp"
            />
            <img
              class="middle-img"
              style="height:400px"
              src="../../assets/images/project/xishan-3.webp"
            />
            <img
              class="middle-img"
              style="height:400px"
              src="../../assets/images/project/xishan-4.webp"
            /><img
            class="middle-img"
            style="height:400px"
            src="../../assets/images/home/鱼类.webp"
          />
          </div>
        </div>
      </div>

      <div v-if="id == '101'">
        <div class="tp-title">邯郸市大名县餐厨垃圾 PPP 项目</div>
        <div class="tp-ic"></div>
        <div class="tp-body"><div class="underline"></div></div>
        <div class="tp-content">
          <div class="middle">
            <div class="tp-text">邯郸市大名县餐厨垃圾 PPP 项目：设计、工程设备 </div>
            <div class="tp-text">规模：餐厨垃圾 50 吨/日 </div>
            <div class="tp-text">
              餐厨处理工艺：预处理+厌氧水处理+好氧堆肥处理
            </div>
            <img
              class="middle-img"
              src="../../assets/images/home/大名1.webp"
            />
            <img
              class="middle-img"
              src="../../assets/images/home/大名2.webp"
            />
            <img
              class="middle-img"
              src="../../assets/images/solution/519-3.png"
            />
          </div>
        </div>
      </div>
      <div v-if="id == '102'">
        <div class="tp-title">苏州厨余垃圾项目(改造)</div>
        <div class="tp-ic"></div>
        <div class="tp-body"><div class="underline"></div></div>
        <div class="tp-content">
          <div class="middle">
            <div class="tp-text">苏州厨余垃圾项目：北厂改造设计、工程设备 </div>
            <div class="tp-text">
              规模：日处理300吨/日项目（投产）
            </div>
            <img
              class="middle-img"
              src="../../assets/images/project/120-1.png"
            />
            <img
              class="middle-img"
              src="../../assets/images/project/120-2.png"
            />
          </div>
        </div>
      </div>
      <div v-if="id == '103'">
        <div class="tp-title">武穴餐厨垃圾项目</div>
        <div class="tp-ic"></div>
        <div class="tp-body"><div class="underline"></div></div>
        <div class="tp-content">
          <div class="middle">
            <div class="tp-text">武穴餐厨垃圾项目：设计、工程设备</div>
         
            <div class="tp-text">
              规模：日处理餐厨+地沟油60吨/日项目
            </div>
            <div class="tp-text">处理工艺：预处理、厌氧、污水处理</div>
            <img
              class="middle-img"
              src="../../assets/images/project/777-2.png"
            />
            <img class="middle-img"
              src="../../assets/images/project/wuxue1.png"   />
          </div>
        </div>
      </div>
      <div v-if="id == '104'">
        <div class="tp-title">达州餐厨垃圾项目</div>
        <div class="tp-ic"></div>
        <div class="tp-body"><div class="underline"></div></div>
        <div class="tp-content">
          <div class="middle">
            <div class="tp-text">达州餐厨垃圾项目：设计、工程设备</div>
           
            <div class="tp-text">
              规模：日处理餐厨+地沟油300吨/日项目（在建）
            </div>
            <div class="tp-text">处理工艺：预处理、厌氧、污水处理</div>
             <img
              class="middle-img"
              src="../../assets/images/project/666-2.png"
            />
             <img
              class="middle-img"
              src="../../assets/images/project/666-3.png"
            />
          </div>
        </div>
      </div>
      <div v-if="id == '105'">
        <div class="tp-title">苏州金庭现代农业生态循环示范产业园（绿光研发基地）</div>
        <div class="tp-ic"></div>
        <div class="tp-body"><div class="underline"></div></div>
        <div class="tp-content">
          <div class="middle">
            <div class="tp-text">项目名称：苏州金庭现代农业生态循环示范产业园（绿光研发基地）</div>
            <div class="tp-text">
              科研基地、现代有机农业、生态养殖、观光农业、农村电商，全国最具特色的现代农业生态循环示范产业园
            </div>
            <img
              class="middle-img"
              src="../../assets/images/project/1650002900.png"
            />
          </div>
        </div>
      </div>
      <div v-if="id == '2'">
        <div class="tp-title">射洪市餐厨垃圾处理项目</div>
        <div class="tp-ic"></div>
        <div class="tp-body"><div class="underline"></div></div>
        <div class="tp-content">
          <div class="middle">
            <div class="tp-text">射洪市餐厨垃圾处理项目：设计、工程设备</div>
            <div class="tp-text">
              规模：日处理餐厨+厨余废弃物200吨/日项目 （在建）
            </div>
            <div class="tp-text">
              处理工艺：预处理+厌氧水处理+好氧堆肥处理
            </div>
            <img
              class="middle-img"
              src="../../assets/images/project/1648888333.png"
            />
            <img
              class="middle-img"
              src="../../assets/images/project/2-1 (2).png"
            />
            <img
              class="middle-img"
              src="../../assets/images/project/2-1 (3).png"
            />
          </div>
        </div>
      </div>
      <div v-if="id == '3'">
        <div class="tp-title">广安市餐厨废弃物处理项目</div>
        <div class="tp-ic"></div>
        <div class="tp-body"><div class="underline"></div></div>
        <div class="tp-content">
          <div class="middle">
            <div class="tp-text">广安市餐厨废弃物处理项目：设计、工程设备</div>
            <div class="tp-text">规模：日处理餐厨+厨余废弃物260吨/日项目</div>
            <div class="tp-text">处理工艺：预处理、三相分离</div>
            <img
              class="middle-img"
              src="../../assets/images/project/3-1 (3).png"
            />
            <img
              class="middle-img"
              src="../../assets/images/project/59-4.jpg"
            />
          </div>
        </div>
      </div>
      <div v-if="id == '4'">
        <div class="tp-title">昆明市餐厨垃圾处理项目（改造）</div>
        <div class="tp-ic"></div>
        <div class="tp-body"><div class="underline"></div></div>
        <div class="tp-content">
          <div class="middle">
            <div class="tp-text">昆明市餐厨垃圾处理项目（改造）：设计、工程设备</div>
            <div class="tp-text">规模：餐厨垃圾450吨/日</div>
            <div class="tp-text">处理工艺：预处理、三相分离</div>
            <img
              class="middle-img"
              src="../../assets/images/project/4-1 (2).png"
            />
            <img
              class="middle-img"
              src="../../assets/images/project/4-1 (1).png"
            />
          </div>
        </div>
      </div>
      <div v-if="id == '5'">
        <div class="tp-title">亳州市餐厨废弃物处理项目</div>
        <div class="tp-ic"></div>
        <div class="tp-body"><div class="underline"></div></div>
        <div class="tp-content">
          <div class="middle">
            <div class="tp-text">亳州市餐厨废弃物处理项目：设计、工程设备</div>
            <div class="tp-text">规模：餐厨垃圾200吨/日、地沟油20吨/日</div>
            <div class="tp-text">处理工艺：预处理、厌氧、污水处理</div>
            <img
              class="middle-img"
              src="../../assets/images/project/5-1 (2).png"
            />
            <img
              class="middle-img"
              src="../../assets/images/project/5-1 (1).png"
            />
          </div>
        </div>
      </div>
      <div v-if="id == '6'">
        <div class="tp-title">徐州丰县餐厨垃圾项目</div>
        <div class="tp-ic"></div>
        <div class="tp-body"><div class="underline"></div></div>
        <div class="tp-content">
          <div class="middle">
            <div class="tp-text">徐州丰县餐厨垃圾项目：设计、工程设备</div>
            <div class="tp-text">规模：日处理餐厨+地沟油50吨/日项目</div>
            <div class="tp-text">处理工艺：预处理</div>
            <img
              class="middle-img"
              src="../../assets/images/project/6-1 (1).png"
            />
            <img
              class="middle-img"
              src="../../assets/images/project/6-1 (2).png"
            />
            <img
              class="middle-img"
              src="../../assets/images/project/6-1 (3).png"
            />
          </div>
        </div>
      </div>
      <div v-if="id == '7'">
        <div class="tp-title">陕西汉中餐厨废弃物处理项目</div>
        <div class="tp-ic"></div>
        <div class="tp-body"><div class="underline"></div></div>
        <div class="tp-content">
          <div class="middle">
            <!-- <div class="tp-text">余姚市餐厨垃圾项目（改造）：设计、工程设备、资源化（蝇蛆养殖）</div>          
            <div class="tp-text">规模：餐厨垃圾200吨/日</div> -->
            <div class="tp-text">建设中</div>
            <img class="middle-img" src="../../assets/images/project/555.webp" />
            <img class="middle-img" src="../../assets/images/project/666.webp" />
          </div>
        </div>
      </div>
      <div v-if="id == '71'">
        <div class="tp-title">余姚市餐厨垃圾项目（改造）</div>
        <div class="tp-ic"></div>
        <div class="tp-body"><div class="underline"></div></div>
        <div class="tp-content">
          <div class="middle">
            <div class="tp-text">余姚市餐厨垃圾项目（改造）：设计、工程设备、资源化（蝇蛆养殖）</div>          
            <div class="tp-text">规模：餐厨垃圾200吨/日</div>
            <div class="tp-text">处理工艺：预处理、厌氧、污水处理</div>
            <img class="middle-img" src="../../assets/images/project/7-1.png" />
          </div>
        </div>
      </div>
      <div v-if="id == '8'">
        <div class="tp-title">江苏苏州餐厨项目</div>
        <div class="tp-ic"></div>
        <div class="tp-body"><div class="underline"></div></div>
        <div class="tp-content">
          <div class="middle">
            <div class="tp-text">江苏苏州餐厨项目：设计、工程设备</div>
            <div class="tp-text">规模：日处理餐厨+地沟油300+50吨/日项目（投产）</div>
            <div class="tp-text">处理工艺：预处理、厌氧、污水处理</div>
            <img
              class="middle-img"
              style="height:400px;"
              src="../../assets/images/project/100-1.png"
            />
            <img
              class="middle-img"
              src="../../assets/images/project/100-2.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  methods:{
    goHome(){
        this.$router.go(-1)
      },
  }
};
</script>
<style lang="scss" scoped>
  .backstyle{
  font-size: 20px;
  margin-left: 420px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  width:100px;
  display: block;
  margin-top: 20px;
  background: #8cc6ee;
  border: 1px solid white;
  color: #fff;
  }
.middle {
  width: 676px;
  margin-left: 30px;
  margin-top: 30px;
  margin: auto;
  .middle-img {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.header-top {
  width: 1903px;
  height: 100%;
  margin: auto;
  .tp-title {
    text-align: center;
    margin-top: 35px;
    color: #1f1f1f;
    font-size: 31px;
  }
  .tp-ic {
    width: 40px;
    height: 4px;
    margin: 20px auto;
    background: #76c817;
  }
  .tp-content {
    width: 1100px;
    display: flex;
    flex-wrap: wrap;
    margin: 20px auto;
    .tp-text {
      font-size: 18px;
      color: #1f1f1f;
      line-height: 35px;
      margin: auto;
      width: 560px;
    }
  }
}

.underline {
  width: 100%;
  height: 18px;
  margin: 20px auto;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/zigongsi/i-title.png");
}
.tp-body {
  width: 560px;
  margin: auto;
}
.tp-img {
  height: 380px;
  width: 100%;
}
</style>
